$navbarHeight: 48px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1366px,
  xxl: 1900px
);
@import "node_modules/bootstrap/scss/bootstrap";
@import "fonts/fonts.scss";


body{
  overflow-x: hidden !important;
  max-width: 100vw !important;
}

.walsh {
  font-family: "Walshes-Regular";
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 140%;
}

// colors
.text-maroon{
  color: #4D3A2D;
}

.text-reddish-white{
  color: #FEF6F6;
}

.text-red-white-border{
  color: #B61616;
  z-index: 5;
  text-shadow:
  -1px -1px 0 #fff,  
   1px -1px 0 #fff,
   -1px 1px 0 #fff,
    1px 1px 0 #fff,
    3px 3px 3px rgba(0,0,0,1);
    // -webkit-text-stroke-width: 0.5px;
    //         -webkit-text-stroke-color: #fff;
}

.text-red-white-border-title{
  color: #B61616;
  z-index: 5;
  -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #fff;
  text-shadow:
  -2px -2px 0 #fff,  
   2px -2px 0 #fff,
   -2px 2px 0 #fff,
    2px 2px 0 #fff,
    2px 2px 2px rgba(0,0,0,1);
}

a{
  text-decoration: none !important;
}

a:hover{
  color: #4D3A2D;
  text-decoration: none !important;
}

.text-decoration-none{
  text-decoration: none !important;
}

.text-spacing-10{
  letter-spacing: 0.1em !important;
}

.text-spacing-20{
  letter-spacing: 20%;
}

#navbar{
  min-height: $navbarHeight;
  background-color: black;
  max-width: 100vw;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #9b7c5c;
  border-radius: 10px;
  background-color: #bfa789;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #724d2b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #724d2b;
}

.policy_background{
  background: url('assets/pages/policy/policy_background.webp') no-repeat ;
  background-size: cover;
}

.error-page-background{
  background: url('assets/pages/ErrorPageBg.webp') no-repeat ;
  background-size: cover;
}

.h0_5{
  font-size: 96px;
}

.h1{
  font-size: 64px;
}

.h2{
  font-size: 56px;
}

.h3{
  font-size: 32px;
}

.h4{
  font-size: 24px;
}

.h5{
  font-size: 18px;
}

.paragraph{
  font-size: 18px;
  letter-spacing: 0.1em;
}

.body-text1 {
  font-size: 16px;
}

@media (min-width: 360px){

  .h0_5{
    font-size: 96px;
  }

  .h1{
    font-size: 64px;
  }

  .h2{
    font-size: 56px;
  }

  .h3{
    font-size: 32px;
  }

  .h4{
    font-size: 24px;
  }

  .h5{
    font-size: 18px;
  }

  .paragraph{
    font-size: 18px;
    letter-spacing: 0.1em;
  }

  .body-text1 {
    font-size: 16px;
  }
}

@media (min-width:576px){
  
}

@media (min-width:768px){

  .h0_5{
    font-size: 140px;
  }

  .h1{
    font-size: 96px;
  }

  .h2{
    font-size: 64px;
  }

  .h3{
    font-size: 40px;
  }

  .h4{
    font-size: 32px;
  }

  .h5{
    font-size: 24px;
  }

  .paragraph{
    font-size: 24px;
  }

  .body-text1 {
    font-size: 18px;
  }
}

@media (min-width:992px){
  
}

@media (min-width:1366px){
  .h0_5{
    font-size: 180px;
  }
}

@media (min-width:1920px){
  .h0_5{
    font-size: 240px;
  }

  .h1{
    font-size: 128px;
  }

  .h2{
    font-size: 96px;
  }

  .h3{
    font-size: 64px;
  }

  .h4{
    font-size: 40px;
  }

  .h5{
    font-size: 32px;
  }

  .paragraph{
    font-size: 32px;
  }

  .body-text1 {
    font-size: 24px;
  }
}